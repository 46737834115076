import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <h1>404: Oops this does not exist</h1>
    <p>
      You can go back to the main page <Link to="/">by clicking here</Link>{" "}
    </p>
  </Layout>
)

export default NotFoundPage
